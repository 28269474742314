import React from "react"

import { Link } from "gatsby"

import HeroImage from "../assets/services-ps.svg"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Hero = () => (
  <section className="pb-20">
    <div className="container px-5 md:px-12 flex flex-col md:flex-row items-center">
      <div className="md:w-5/12">
        <h1 className="text-4xl mt-16">
          Product <span className="font-black">Strategy</span>
        </h1>
        <h2 className="text-pgray font-black text-2xl mt- mb-6">
          Strategy leads the way
        </h2>
        <p className="text-gray-600 text-sm">
          Doing what's common is never a practice at Touchcore. We develop
          custom software, mobile and web applications by analysing a wealth of
          research, defining business and audience goals, establishing a clear
          vision and identifying success metrics. This makes our digital product
          strategy an intricate story - one that begins and ends with discovery.
        </p>
      </div>
      <img className="md:w-7/12 " src={HeroImage} alt={""} />
    </div>
  </section>
)
const PageQuote = () => (
  <section className="container px-5 md:px-12 pt-16 pb-48 white-dots-bg bg-cover bg-no-repeat">
    <h3 className="text-pgray text-2xl md:text-4xl font-semibold text-center md:w-9/12 mx-auto">
      "Product strategy is about converting energy into directed action."
    </h3>
  </section>
)
const HowWeDoIt = () => {
  const data = [
    {
      title: "Research",
      content:
        "Understanding and assessing the factors that influence project success helps guarantee we hit our target. Touchcore undertakes hands-on, rigorous analysis of user needs and mindsets as well as other factors such as markets, competition, or operations to factors such as markets, competition, or operations to build an essential platform for advancement.",
    },
    {
      title: "Data Analysis",
      content:
        "Touchcore studies data from your existing apps, sites and customer, operational, or financial centers and tests our strategy against this information.",
    },
    {
      title: "The Human Factor",
      content:
        "We understand that making the user the point of the product strategy is ideal in creating the perfect software. Touchcore develops user personas and detailed journey maps for each key audience segment, working from the start to inform intuitive, interactive experiences.",
    },
    {
      title: "Vision",
      content:
        "We give a name to your requirements &mdash; business, user, and functional &mdash; and then bring those goals within our control. An insightful, articulated strategic vision orients the team and keeps disciplines aligned from start to finish.",
    },
  ]
  return (
    <section className="bg-plightpink pink-gradient-bg bg-bottom bg-no-repeat bg-contain">
      <div className="container px-5 md:px-12 py-20">
        <h4 className="font-black text-pgray text-3xl">How we do it</h4>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10 mt-12">
          {data.map(v => (
            <div className="shadow p-5 md:px-16 md:py-20  bg-white text-pgray">
              <h6 className="text-2xl font-black">{v.title}</h6>
              <p className="text-justify md:text-left">{v.content}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
export const OtherServices = () => {
  const data = [
    {
      no: "01",
      title: "Product Strategy",
      content:
        "Hitting the bullseye isn't luck, it's Touchcore product strategy implemented insightfully.",
      image: require("../assets/Tc3/Product strategy.svg"),
      link: "/product-strategy",
    },
    {
      no: "02",
      title: "Product Design",
      content: "The structured process behind successful product development",
      image: require("../assets/Tc3/Product Design.svg"),
      link: "/product-design",
    },
    {
      no: "03",
      title: "Custom App Development",
      content:
        "Bit by bit, Touchcore's expert engineers construct scalable, pixel-perfect custom mobile or web based software",
      image: require("../assets/Tc3/Custom app development.svg"),
      link: "/custom-development",
    },
    {
      no: "04",
      title: "Enterprise Solutions",
      content:
        "Run your business from anywhere by automating all your processes into a web based application.",
      image: require("../assets/Tc3/Enterprise solutions.svg"),
      link: "/enterprise-solutions",
    },
    {
      no: "05",
      title: "UI / UX",
      content:
        "Design an engaging product that is easy-to-use, attractive and functional.",
      image: require("./../assets/Tc3/UIUX.svg"),
      link: "/ui-ux",
    },
    {
      no: "06",
      title: "Web Design",
      content:
        "You need more than just a website. You need results. We turn underperforming websites around.",
      image: require("../assets/Tc3/Web design.svg"),
      link: "/web-design",
    },
    {
      no: "07",
      title: "E-commerce",
      content:
        "All the benefits of an in-store experience, straight from your couch. From anywhere. Anytime",
      image: require("../assets/Tc3/Ecommerce.svg"),
      link: "/ecommerce-solutions",
    },
    {
      no: "08",
      title: "Digital Marketing",
      content:
        "A comprehensive digital marketing strategy allows you to capture, engage, and convert your target audience in real-time",
      image: require("../assets/Tc3/Digital marketing.svg"),
      link: "/digital-marketing",
    },
  ]
  return (
    <section className="container px-5 md:px-12 py-10 md:py-20">
      <h4
        data-aos="fade-left"
        className="font-black text-3xl md:text-5xl text-pgray mt-20 mb-12"
      >
        Other services
      </h4>
      <div className="grid grid-cols-1 md:grid-cols-2 col-gap-5 row-gap-10">
        {data.map((v, i) => (
          <Link
            key={"othSer" + i}
            data-aos="fade-left"
            data-aos-delay={200 + 60 * i}
            className="h-full block"
            to={v.link}
          >
            <div className="h-full flex flex-col md:flex-row items-center rounded-lg shadow-md hover:shadow-2xl px-6 py-8 text-pgray">
              <img className="h-24 mr-4" alt="" src={v.image} />
              <div>
                <span>{v.no}</span>
                <h6 className="font-black text-lg">{v.title}</h6>
                <p className="mt-3">{v.content}</p>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </section>
  )
}
const ProductStrategy = () => (
  <Layout insightCategory="product-strategy">
    <SEO title="Product Strategy" />
    <Hero />
    <PageQuote />
    <HowWeDoIt />
    <OtherServices />
  </Layout>
)
export default ProductStrategy
